body {
    .ant-table-thead {
        font-size: 14px;
    }
    .ant-form-item {
        // margin-bottom: 36px;
    }
    .app.ant-form-css-var {
        margin-bottom: 14px;
    }
    .ant-modal .ant-modal-content {
        border-radius: 4px;
    }
    .ant-table-cell {
        word-break: break-all;
    }
    // .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    //     background: var(--primaryColor)!important;
    // }
    // .ant-btn-primary {
    //     background: var(--primaryColor)!important;
    // }
    // .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    //     background: var(--primaryColor)!important;
    // }
    .ant-modal-body {
        margin-top: 20px;
    }
}
