@import './antd.less';
:root {
    --primaryColor: #68AB7E;
}
* {
    padding: 0;
    margin: 0;
}
html,body {
    width: 100%;
    height: 100%;
    background: #fff;
    font-size: 14px;
}
#root {
    width:100%;
    height: 100%;
}
i.required {
    color: red;
    margin-right: 4px;
    font-family: normal;
    font-style: normal;
}
.fontsize14 {
    font-size: 14px;
}
.fontsize16 {
    font-size: 16px;
}
.fontsize18 {
    font-size: 18px;
}
.fontsize10 {
    font-size: 10px;
}

@primary-color: #1DA57A;